<div class="h-content-layout py-2xl">
  <p role="heading" aria-level="3" class="mb-2xs text-center plot-base sm:text-left">{{ plot }}</p>
  <p role="heading" aria-level="2" class="text-center heading-xl sm:text-left">{{ title }}</p>
  <div class="mt-xl flex gap-6">
    <div class="flex w-full flex-wrap justify-center sm:w-2/5">
      <div class="ml-5 mr-5 flex items-center sm:ml-2 sm:mr-0 sm:max-w-[260px] md:m-0">
        <ng-content select="cft-list-carousel"></ng-content>
      </div>
      <div class="mt-lg w-full sm:hidden">
        <ng-content select="cft-list-slider"></ng-content>
      </div>
      <div class="flex w-full justify-center py-2">
        <ng-content select="cft-list-bullet-indicator"></ng-content>
      </div>
    </div>
    <div class="sm-m-w-[640px] hidden w-full items-center sm:flex sm:w-3/5">
      <ng-content select="cft-accordion"></ng-content>
    </div>
  </div>
</div>
