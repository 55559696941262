import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

@Component({
  selector: 'cft-carousel-accordion',
  templateUrl: './carousel-accordion.component.html',
  standalone: true,
  styles: [
    `
      :host {
        @apply block bg-grey-50;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselAccordionComponent {
  @Input() plot!: string
  @Input() title!: string

  protected readonly activeItemIndex$ = new BehaviorSubject(0)
}
