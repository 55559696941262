@if (section(); as section) {
  <cft-carousel-accordion [attr.id]="section.id" [plot]="section.plot" [title]="section.title">
    <cft-list-carousel (activated)="changeIndex($event)" [activeItemIndexAt$]="index$">
      @for (item of section.items; track item) {
        @if (item.youtubeId) {
          <div cftListCarouselSlide>
            <cft-youtube-video
              width="100%"
              height="100%"
              [showControls]="false"
              [modestBranding]="false"
              [videoId]="item.youtubeId"
              data-testid="carousel-youtube-video"
            ></cft-youtube-video>
          </div>
        } @else if (item.image?.url | isDatoCmsGif) {
          <video
            data-testid="carousel-video"
            cftListCarouselSlide
            class="portrait:max-h-[50vh]"
            autoplay
            loop
            muted
            playsinline
          >
            <source [src]="item.image?.url + '?fm=webm'" type="video/webm" />
            <source [src]="item.image?.url + '?fm=mp4'" type="video/mp4" />
          </video>
        } @else if (item.image) {
          <div cftListCarouselSlide class="flex items-center justify-center">
            <img
              data-testid="carousel-image"
              class="portrait:max-h-[50vh]"
              width="544"
              height="306"
              [ngSrc]="item.image.url"
              [alt]="item.image.title"
              [title]="item.image.title"
            />
          </div>
        }
      }
    </cft-list-carousel>
    <cft-list-slider (activated)="changeIndex($event)" [activeItemIndexAt$]="index$">
      @for (item of section.items; track item; let i = $index) {
        <cft-accordion-element cftListSliderItem whitespace="sm" [title]="item.title" [letter]="getLetter(i)">
          <bgo-rich-text [content]="item.content"></bgo-rich-text>
          @if (item.primaryCallToAction || item.primaryCallToAction) {
            <cft-accordion-element-footer>
              <div class="flex gap-sm">
                @if (item.secondaryCallToAction) {
                  <cft-call-to-action
                    [callToAction]="item.secondaryCallToAction"
                    [showIcon]="false"
                    callToActionStyle="button-sm"
                  ></cft-call-to-action>
                }
                @if (item.primaryCallToAction) {
                  <cft-call-to-action
                    callToActionStyle="button-sm"
                    [showIcon]="false"
                    buttonStyle="primary"
                    [callToAction]="item.primaryCallToAction"
                  ></cft-call-to-action>
                }
              </div>
            </cft-accordion-element-footer>
          }
        </cft-accordion-element>
      }
    </cft-list-slider>
    <cft-list-bullet-indicator
      (activated)="changeIndex($event)"
      [itemsCount]="section.items.length"
      [activeIndexAt$]="index$"
    ></cft-list-bullet-indicator>
    <cft-accordion (activated)="changeIndex($event)" [activeIndexAt$]="index$">
      @for (item of section.items; track item; let i = $index) {
        <cft-accordion-element cftListSliderItem whitespace="sm" [title]="item.title" [letter]="getLetter(i)">
          <bgo-rich-text [content]="item.content"></bgo-rich-text>
          @if (item.primaryCallToAction || item.primaryCallToAction) {
            <cft-accordion-element-footer>
              <div class="flex gap-sm">
                @if (item.secondaryCallToAction) {
                  <cft-call-to-action
                    [callToAction]="item.secondaryCallToAction"
                    callToActionStyle="button"
                  ></cft-call-to-action>
                }
                @if (item.primaryCallToAction) {
                  <cft-call-to-action
                    callToActionStyle="button"
                    [callToAction]="item.primaryCallToAction"
                    buttonStyle="primary"
                    [showIcon]="false"
                  ></cft-call-to-action>
                }
              </div>
            </cft-accordion-element-footer>
          }
        </cft-accordion-element>
      }
    </cft-accordion>
  </cft-carousel-accordion>
}
